<div id="container">
    <div id="content">
        <div class="content-menu">
            <h4>Navigation</h4>
            <a [routerLink]="['/partis']" >Parti Politique</a>
            <a [routerLink]="['/personalites']" >Personalité Politique</a>
        </div>
        <div class="content-menu">
            <h4>Mentions Légales</h4>
            <a [routerLink]="['/terms-of-service']" >Conditions Générales</a>
            <a [routerLink]="['/privacy-policy']" >Politique de Confidentialité</a>
            <a [routerLink]="['/legal-notice']" >Mentions Légales</a>
        </div>
    </div>
    <div id="bottom">
        <p>© 2024 E-democracy. Tous droits réservés</p>
    </div>
</div>