<div id="content">
    <i id="close-button" (click)="onClose()" class="fa-solid fa-xmark"></i>
    <h4 style="margin: 0">Rejoignez-nous</h4>
    <p style="margin:5px 0 0 0">
        Vous croyez en une politique plus transparente et accessible à tous ?
        Vous souhaitez vous impliquer dans un projet innovant et porteur de changement ?
        Rejoignez l'aventure de Digital Démocracy : contribuez bénévolement à faire grandir
        une plateforme citoyenne et militante, avec des opportunités futures de postes
        rémunérés et un impact réel sur la politique de demain !
    </p>
    <p>job&#64;digital-democracy.com</p>
    <h4 style="margin: 5px 0 0 0">Soutenez-nous</h4>
    <p style="margin: 5px 0 0 0">
        Votre don nous aide à faire vivre la démocratie.
    </p>
    <div id="quick-donate">
        <button 
            class="validate-button"
            [disabled]="!isEmailCorrect"
            (click)="onQuickDonate(5)">
            5€
        </button>
        <button 
            class="validate-button"
            [disabled]="!isEmailCorrect"
            (click)="onQuickDonate(10)">
            10€
        </button>
        <button 
            class="validate-button"
            [disabled]="!isEmailCorrect"
            (click)="onQuickDonate(20)">
            20€
        </button>
    </div>

    <!--Input amount-->
    <div id="custom-amount" style="margin-top:10px">
        <input 
            type="number" 
            placeholder="Montant de votre don" 
            [(ngModel)]="amount" 
            class="input">

    </div>

    <!--Recurring-->
    <div id="recurring" style="margin-top:10px">
        <div>
            <input 
            type="checkbox" 
            [(ngModel)]="isPonctual"
            (change)="onPonctual()"
            id="recurring-checkbox">Don ponctuel
        </div>
        <div>
            <input 
            type="checkbox" 
            [(ngModel)]="isMonthly" 
            (change)="onMonthly()"
            id="recurring-checkbox">Don mensuel
        </div>
        <div>
            <input 
            type="checkbox" 
            [(ngModel)]="isYearly"
            (change)="onYearly()" 
            id="recurring-checkbox">Don annuel
        </div>
        
    </div>

    <!--Input mail-->
    <input style="margin-top:5px"type="email" placeholder="Votre email (optionnel)" [(ngModel)]="email" class="input"/>

    <button 
        id="donate-button"
        class="validate-button"
        [disabled]="!isEmailCorrect || !isAmountValid"
        (click)="onDonate()">Donner</button>

</div>